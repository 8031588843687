import { useState, useEffect } from "react";
import { Filters, 
  // Pagination, 
  FiltersSidebar, VideoContentList,
    // ReelContentList,
  // AudioContentList,
} from "../../components";
import { XMLParser } from "fast-xml-parser";
import BottomPaginate from "../../components/paginate/BottomPaginate";
import Select from "../../UI/select/Select";


export const SearchResults = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchVideos = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}`);
      if (!response.ok) {
        throw new Error(`Ошибка: ${response.statusText}`);
      }

      const xmlText = await response.text();
      const parser = new XMLParser();
      const jsonData = parser.parse(xmlText);


      const items = jsonData?.result?.items?.item?.map((item) => ({
        id: item.imageid,
        title: item.title,
        videoUrl: item.videoLargeThumb,
        imageUrl: item.largeThumb,
        duration: `${item.width}x${item.height}`,
      })) || [];
      setVideoData(items);
      setPages(items.length )
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
      alert("Произошла ошибка при загрузке данных. Попробуйте снова позже.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  if (loading) return <div>Загрузка...</div>;
  if (!videoData.length) return <div>Нет данных для отображения</div>;

  return (
    <div className="container mx-auto px-10 flex flex-col text-[#1c2024]">
      <Filters setSidebarOpen={setSidebarOpen} />

      <div className="flex gap-[9px] mt-6">
        {sidebarOpen && <FiltersSidebar />}
        <VideoContentList xmlData={videoData} />
           {/* <ReelContentList data={reelData} /> */}
        {/* <AudioContentList sidebarOpen={sidebarOpen} data={videoData} /> */}
      </div>
      <div className="bottom">
            <BottomPaginate pageCount={pages} />
            <Select list={[48, 64, 96, 120]}>Количество на странице:</Select>
          </div>
      {/* <Pagination  /> */}
    </div>
  );
};
